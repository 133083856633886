<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="countNotification || 0"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <b-spinner v-if="isLoading" small />
      <small v-if="isLoading"><i> Loading notification... </i></small>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary">
          {{ countNotification }} New
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link v-for="(notification, index) in notifications" :key="index">
        <b-media
          :style="
            notification.is_read === true
              ? 'background-color: white !important'
              : 'background-color: whitesmoke !important'
          "
          @click="onTapNotification(index)"
        >
          <template #aside>
            <!-- <b-avatar size="32" src="" :variant="notification.type"> -->
            <b-avatar size="32" src="" :variant="primary">
              <feather-icon size="25" :icon="'PersonIcon'" />
            </b-avatar>
          </template>

          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.action }}
              {{ notification.full_name }}
            </span>
            <code>{{ moment(notification.created_at).fromNow() }}</code>
          </p>
          <small class="notification-text">
            <!-- {{ notification.full_name }} -->
            <b-badge variant="primary">
              {{ notification.badge }}
            </b-badge>
            &nbsp;
            <b-badge variant="info">
              {{ notification.from_company }}
            </b-badge>
            &nbsp;
            <b-badge variant="warning" @click="copyText(notification.desc1)">
              {{ notification.ket }}
            </b-badge>
            <p v-if="notification.state_induction === false">
              <b-badge
                class="w-100"
                variant="danger"
                @click="copyText(notification.desc1)"
              >
                Alert Induction
              </b-badge>
            </p>
          </small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="readAllNotification()"
        >Read all notifications</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import moment from 'moment'
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox
  },
  directives: {
    Ripple
  },
  data() {
    return {
      registrationToken: '',

      isLoading: false,
      notifications: [],
      errorRequest: false,
      timeoutDebounce: null
      // roleName: JSON.parse(localStorage.getItem('userDataVisitor'))?.visitor_role ?? '',
      // groupName: JSON.parse(localStorage.getItem('userDataVisitor')).group?.name ?? ''
    }
  },
  setup() {
    // const { watchNotification } = useAppConfig()

    // /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: 'Congratulation Sam 🎉',
    //     avatar: require('@/assets/images/avatars/6-small.png'),
    //     subtitle: 'Won the monthly best seller badge',
    //     type: 'light-success'
    //   },
    //   {
    //     title: 'New message received',
    //     avatar: require('@/assets/images/avatars/9-small.png'),
    //     subtitle: 'You have 10 unread messages',
    //     type: 'light-info'
    //   },
    //   {
    //     title: 'Revised Order 👋',
    //     avatar: 'MD',
    //     subtitle: 'MD Inc. order updated',
    //     type: 'light-danger'
    //   }
    // ]
    // /* eslint-disable global-require */

    // const systemNotifications = [
    //   {
    //     title: 'Server down',
    //     subtitle: 'USA Server is down due to hight CPU usage',
    //     type: 'light-danger',
    //     icon: 'XIcon'
    //   },
    //   {
    //     title: 'High memory usage',
    //     subtitle: 'BLR Server using high memory',
    //     type: 'light-warning',
    //     icon: 'AlertTriangleIcon'
    //   }
    // ]

    const { lookWatchException, watchNotification } = useAppConfig()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }

    let countNotification = 0

    return {
      // notifications,
      // systemNotifications,
      perfectScrollbarSettings,
      // watchNotification
      countNotification,
      lookWatchException,
      watchNotification
      // anIncomingNotificationRiver,
      // watchApproval
    }
  },
  watch: {
    lookWatchException(params) {
      /* ERROR HANDLING NEWS */
      const error = params
      try {
        if (error.includes(['expired'])) {
          this.$swal({
            title: '7 Hari Berlalu',
            html: '<strong>Jika pada hari ke 7 tidak membuka Website</strong>, maka akan terjadi force to <strong>logout</strong> (<i>expired login 7 days</i>).',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/pages/error.svg'),
            imageAlt: 'system_update',
            confirmButtonText: 'CLOSE',
            showCancelButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.$forceUpdate()
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => {
                useJwt.errorHandling(null)
                localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                localStorage.removeItem(
                  useJwt.jwtConfig.storageRefreshTokenKeyName
                )
                localStorage.removeItem('userDataVisitor')
                this.$router.push({ name: 'auth-login' })
                // this.$router.go(-1)
              }, 300)
            }
          })
        }
      } catch (error) {
        const [firstKey] = Object.keys(params) // by keys
        const [firstValue] = Object.values(params)

        // this.errorMatchValue = params.value || null // by values
        // this.errorPath = firstKey /* v-model validation */
        // this.errorValue = firstValue

        this.$swal({
          title: 'Error!',
          text: `${JSON.stringify(firstValue)}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    }
  },
  // async mounted() {
  // },
  async created() {
    this.isLoading = true
    await this.fetch()
    await this.listeningFirebase()
    // this.$store.commit('app/UPDATE_ROLE_NAME', this.roleName)
    // console.log(
    //   'this.$store.state.app.roleName::',
    //   this.$store.state.app.roleName
    // )
    // this.fetchPusher()
  },
  methods: {
    moment,
    async readAllNotification() {
      this.$swal({
        title: 'Read Notification',
        html: `Anda tidak memiliki izin untuk membaca notifikasi. Silakan hubungi Admin SAL untuk mendapatkan akses.`,
        timer: 5000,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-warning'
        },
        buttonsStyling: false
      })
      // let isTherePutAction = false
      // await this.notifications
      //   .filter((data) => data.is_read === false)
      //   .map((data) => {
      //     data.is_read = true
      //     useJwt.http.put(`visitor_notification/${data.id}`, '')
      //     isTherePutAction = true
      //   })
      // this.countNotification = 0
      // if (isTherePutAction == true) {
      //   this.fetch()
      // }
    },
    onTapNotification(index) {
      this.$swal({
        title: 'Read Notification',
        html: `Anda tidak memiliki izin untuk membaca notifikasi. Silakan hubungi Admin SAL untuk mendapatkan akses.`,
        timer: 5000,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-warning'
        },
        buttonsStyling: false
      })
      // if (this.notifications[index].is_read === false) {
      //   this.notifications[index].is_read = true
      //   if (this.countNotification !== 0) {
      //     this.countNotification -= 1
      //   }
      //   useJwt.http.put(
      //     `visitor_notification/${this.notifications[index].id}`,
      //     ''
      //   )
      // }
    },
    async fetch() {
      const keyword = `created_at=${moment().format('Y-MM-D')}`
      useJwt.http
        .get('visitor_notification', { params: { keyword } })
        .then((res) => {
          // console.log('object', res.data)
          // const resData = res.data.visitor_notifications.map((data) => ({
          //   ...data
          //   // desc1: data.driver_name,
          //   // desc2: data.plat_no_pol,
          //   // company: data.driver_from_company,
          //   // title: data.user?.full_name ?? 'NA',
          //   // // group: data.group?.name ?? '' // null properties
          //   // group:
          //   //   data.group?.name === 'SLB' ? 'Hulu Migas' : data.group?.name ?? ''
          // }))
          // // console.log(resData)
          // this.notifications = resData
          this.notifications = res.data.visitor_notifications
          // console.table(this.notifications)
          this.countNotification = this.notifications.filter(
            (data) => data.is_read === false
          ).length
          this.isLoading = false
        })
    },
    listeningFirebase() {
      try {
        this.$viFirebase
          .messaging()
          .requestPermission()
          .then(() => {
            this.errorRequest = false
            console.log('Notification permission granted')
            this.$viFirebase
              .messaging()
              .getToken()
              .then((token) => {
                this.$store.commit('visitor/UPDATE_TOKEN_NOTIFICATION', token)
                this.registrationToken = token
                console.log('registrationToken: ', token)

                this.subscribeToTopic(
                  token,
                  `${process.env.VUE_APP_TOPIC_VISITOR}`
                ) /* sal|land */

                // let admin =
                //   this.$can('manage', 'all') /* Host */ ||
                //   this.$can('manage', 'qrcode') /* Security */ ||
                //   this.$can('manage', 'special') /* Admin Agent */ ||
                //   this.$can('approvement', '') /* Admin PIL */

                // let landAccess =
                //   /* Published form */
                //   this.$can('manage', 'form1') ||
                //   this.$can('manage', 'form2') ||
                //   this.$can('manage', 'form3')
                // let riverAccess =
                //   /* Published form */
                //   this.$can('SAL', '') ||
                //   this.$can('TBBM', '') ||
                //   this.$can('TBBM JETTY 3A', '') ||
                //   this.$can('Hulu Migas', '')

                // if (admin || landAccess) {
                //   console.info(
                //     'subscribe to topic ::',
                //     process.env.VUE_APP_TOPIC_LAND
                //   )
                //   this.subscribeToTopic(
                //     token,
                //     `${process.env.VUE_APP_TOPIC_LAND}`
                //   ) /* sal|land */
                // }

                // if (admin || riverAccess) {
                //   console.info(
                //     'subscribe to topic ::',
                //     process.env.VUE_APP_TOPIC_RIVER
                //   )
                //   this.subscribeToTopic(
                //     token,
                //     `${process.env.VUE_APP_TOPIC_RIVER}`
                //   ) /* river */
                // }
              })
          })
          .catch((err) => {
            console.log('Unable to get token ', err)
            this.errorRequest = true
            // this.$parent.showAlert()
          })
      } catch (e) {
        console.log(e)
      }
    },
    async subscribeToTopic(token, topic) {
      await useJwt.http
        .post(`notification/subscribe/topic/${topic}/visitor`, {
          registrationToken: token
        })
        .then((success) => {
          console.log(success.data.message)
          this.errorRequest = false
          this.receiveMessage()
        })
        .catch((error) => {
          this.errorRequest = true
          console.log('error subscribe to topic', error)
          this.$parent.showAlert()
        })
    },
    async receiveMessage() {
      try {
        this.$viFirebase.messaging().onMessage((payload) => {
          this.fetch()
          console.log('payload here', payload)

          const action = payload.data.action
          const tag = payload.data.tag
          const fcmMessageId = payload.fcmMessageId

          this.$alert({
            title: 'Notification',
            icon: 'InfoIcon',
            text: `${payload.notification.title}, ${payload.notification.body}`,
            varianty: 'primary'
          })

          if (tag === 'visitor') {
            console.log('visitor notification trigger')
            this.watchNotification = payload
          }
          // if (action.includes('Clock')) {
          //   this.$store.commit('app/UPDATE_WATCH_NOTIFICATION_CLOCK_IN', '')
          // } else {
          //   this.$store.commit('app/UPDATE_WATCH_NOTIFICATION', '')
          // }

          // console.log('tag notification::', tag)
          // if (tag === 'river_route') {
          //   this.watchNotificationRiver = payload
          // }

          // khusus approval limit
          // if (action.includes(' ( ✓ Approved )')) {
          //   console.log('Approved::')
          //   this.watchApproval = fcmMessageId
          //   console.log('Approved::', this.watchApproval)
          // }
          // if (action.includes(' ( ✓ Clock OUT )')) {
          //   console.log('Clock OUT::')
          //   this.watchApproval = fcmMessageId
          //   console.log('Clock OUT::', this.watchApproval)
          // }
          // if (action.includes(' ( 𝕩 Cancels Approved )')) {
          //   console.log('Cancels Approved::')
          //   this.watchApproval = fcmMessageId
          //   console.log('Cancels Approved::', this.watchApproval)
          // }
        })
      } catch (e) {
        console.log(e)
        this.errorRequest = true
        this.$parent.showAlert()
      }
    }
  }
}
</script>

<style></style>
